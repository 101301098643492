import { css } from 'styled-components';

type TransitionProps = {
  animationTime?: number;
  animationTimeout?: number;
};

interface IAnimationProps extends TransitionProps {
  isStart: boolean

  /**
   * vertical: minus for ↓, plus for ↑
   * horizontal: minus for →, plus for ←
   */
  translateValue?: string;
}

const transition = css<TransitionProps>`
  transition: transform ${({ animationTime = 400 }) => animationTime}ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity ${({ animationTime = 400 }) => animationTime * 0.75}ms cubic-bezier(0.4, 0, 0.2, 1),
    height ${({ animationTime = 400 }) => animationTime}ms cubic-bezier(0.4, 0, 0.2, 1),
    margin-bottom ${({ animationTime = 400 }) => animationTime}ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility ${({ animationTime = 400 }) => animationTime}ms linear 0s;

  transition-delay: ${({ animationTimeout = 0 }) => animationTimeout}ms;
`;

/* SLIDE HORIZONTAL */
export const StyledAnimationSlideHorizontal = css<IAnimationProps>`
  opacity: ${props => props.isStart ? '1' : '0'};
  visibility: ${props => props.isStart ? 'visible' : 'hidden'};

  transform: translateX(${({ isStart, translateValue = '-10%' }) => isStart ? '0%' : translateValue});
  -ms-transform: translateX(${({ isStart, translateValue = '-10%' }) => isStart ? '0%' : translateValue});
  -moz-transform: translateX(${({ isStart, translateValue = '-10%' }) => isStart ? '0%' : translateValue});
  -webkit-transform: translateX(${({ isStart, translateValue = '-10%' }) => isStart ? '0%' : translateValue});

  ${transition}
`;

/* SLIDE VERTICAL */
export const StyledAnimationSlideVertical = css<IAnimationProps>`
  opacity: ${props => props.isStart ? '1' : '0'};
  visibility: ${props => props.isStart ? 'visible' : 'hidden'};

  transform: translateY(${({ isStart, translateValue = '10%' }) => isStart ? '0%' : translateValue});
  -ms-transform: translateY(${({ isStart, translateValue = '10%' }) => isStart ? '0%' : translateValue});
  -moz-transform: translateY(${({ isStart, translateValue = '10%' }) => isStart ? '0%' : translateValue});
  -webkit-transform: translateY(${({ isStart, translateValue = '10%' }) => isStart ? '0%' : translateValue});
  ${transition}
`;

/* SCALE */
export const StyledAnimationScale = css<IAnimationProps>`
  opacity: ${props => props.isStart ? '1' : '0'};
  visibility: ${props => props.isStart ? 'visible' : 'hidden'};

  transform: scale(${props => props.isStart ? '1' : '0'}) translateY(${({ isStart, translateValue = '-20%' }) => isStart ? '0%' : translateValue});
  -ms-transform: scale(${props => props.isStart ? '1' : '0'}) translateY(${({ isStart, translateValue = '-20%' }) => isStart ? '0%' : translateValue});
  -moz-transform: scale(${props => props.isStart ? '1' : '0'}) translateY(${({ isStart, translateValue = '-20%' }) => isStart ? '0%' : translateValue});
  -webkit-transform: scale(${props => props.isStart ? '1' : '0'}) translateY(${({ isStart, translateValue = '-20%' }) => isStart ? '0%' : translateValue});

  ${transition}
`;

/* FADE */
export const StyledAnimationFade = css<IAnimationProps>`
  opacity: ${props => props.isStart ? '1' : '0'};
  visibility: ${props => props.isStart ? 'visible' : 'hidden'};

  ${transition}
`;

export const StyledLineEllipsis = css<{ numberOfLines: number }>`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.numberOfLines};
`;
