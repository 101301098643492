import { Box, Dialog, DialogContent, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { useTranslations } from "next-intl"
// import { useTranslations } from "next-intl"
import { useCallback, useEffect } from "react"
import styled from "styled-components"
import ContactFormIframe from "./ContactFormIframe"

//Styles
const StyledDialog = styled(Dialog)`
  ${(p) => p.theme.breakpoints.up("md")} {
    .MuiDialog-paper {
      max-width: 489px;
    }
  }
`

const StyledDialogContent = styled(DialogContent)`
  padding: 30px 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 30px;
    /* max-width: 489px; */
  }
`

// const StyledContactHeader = styled(Typography)`
//   margin-bottom: 15px;

//   ${(p) => p.theme.breakpoints.down("sm")} {
//     max-width: 320px;
//   }
// `

const ContactUsPopup = ({ open, close }): JSX.Element => {
  // const t = useTranslations("assetSlug")
  const t = useTranslations("contactForm")

  const closeFunction = useCallback((event) => {
    if (event.data === "closePopup") {
      console.log("closePopup")
      close()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("message", closeFunction)
    return () => window.removeEventListener("message", closeFunction)
  }, [closeFunction])

  //Render
  return (
    <StyledDialog
      aria-label={t("details")}
      aria-modal="true"
      role="dialog"
      open={open}
      fullWidth
      onClose={close}
    >
      <StyledDialogContent>
        <Box display="flex" justifyContent="space-between">
          <Box />
          <IconButton onClick={close} aria-label="סגור חלונית">
            <Close />
          </IconButton>
        </Box>
        {/* <StyledContactHeader variant="body1">
          {t("popupText")}
        </StyledContactHeader> */}
        <ContactFormIframe withCompany={false} />
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default ContactUsPopup
