const colors = {
  blackDark: "#000000",
  black: "#000615",
  white: "#fff",
  gray: "#5b626a",
  grayButton: "#333333",
  marine: "#092a46",
  oceanBlueTwo: "#0076ab",
  orange: "#ff830c",
  warmGrey: "#9f9f9f",
  lightGray: "#ebebeb",
  darkGray: "#8f9091",
  whiteGray: "#dbdbdb",
  whiteDark: "#b1b1b1",
  silver: "#ebebeb",
  red: "#d9001d",
  hoverRed: "#a80016",
  mainBack: "#101619",
  error: "#ff33333",
  bluishGrey: "#6699cc",
  duskBlue: "#5c777f",
  oceanBlue: "#009dc4",
  pistachio: "#95b409",
  paleGrey: "#9fa0a4",
  placeHolderGray: "#c1c1c1",
  purple: "#9943f8",
  electricViolet: "#9943f8",
  mineShalf: "#282828",
  yellowishGreen: "#b3d519",
  abbey: "#d16c0b",
  bulletsGray: "#abbcc9",
  alabaster: "#f8f8f8",
  athensGray: "#e7eaf0",
  doveGray: "#707070",
  transparent: "transparent",
  none: "none",
  noActiveActivityButton: "#faf5f6",
  activitiesBorder: "#d9d9d9",
}

export default colors
