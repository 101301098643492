import { heIL } from "@material-ui/core/locale"
import { createTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { Overrides } from "@material-ui/core/styles/overrides"
import colors from "./colors"

const breakpoints = createBreakpoints({})

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  spacing: 6,
  typography: {
    fontFamily: '"FbJabutinskiEng","FbJabutinski", sans-serif',
    h1: {
      fontSize: "70px",
      fontWeight: "normal",
      fontFamily: "'FbJabutinskiEng-Con','FbJabutinski-Con', sans-serif",
      color: colors.mainBack,
      textAlign: "left",
      [breakpoints.down("sm")]: {
        fontSize: "44px",
        textAlign: "left",
      },
    },
    h2: {
      fontSize: 20,
      color: colors.mainBack,
      // textAlign: "center",
      fontWeight: "normal",
      letterSpacing: "0.4px",
      fontFamily: "'FbJabutinskiEng-Con','FbJabutinski-Con', sans-serif",
    },
    h3: {
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      // [breakpoints.up("md")]: {
      //   fontSize: "1rem",
      // },
    },
    h4: {
      fontSize: "14px",
      fontWeight: "normal",
      letterSpacing: "normal",
      color: colors.black,
      lineHeight: "1.57",
      // [breakpoints.up("md")]: {
      //   fontSize: "1rem",
      // },
    },
    body1: {
      fontSize: "14px",
      color: colors.mainBack,
      lineHeight: "1.2",
    },
    body2: {
      fontSize: "12px",
      color: colors.gray,
      lineHeight: "1.2",
      fontWeight: 300,
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.mainBack,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.black,
    },
    text: {
      primary: colors.black,
      // secondary: colors.orange,
    },
    error: {
      main: "#b50303",
    },
  },
  props: {
    MuiContainer: {
      maxWidth: "lg",
    },
    MuiFormControl: {
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
      variant: "outlined",
      autoComplete: "true",
    },

    // MuiOutlinedInput: {
    //   notched: true,
    // },
    MuiButton: {
      // disableTouchRipple: true,
      // disableFocusRipple: true,
      // disableRipple: true,
    },
  },
}

const overrides: Overrides = {
  MuiFormControl: {
    root: {
      borderRadius: "16px",
    },
  },
  MuiTabs: {
    // indicator: {
    //   backgroundColor: colors.black,
    // },
    scroller: {
      backgroundColor: colors.white,
      color: colors.black,
    },
    flexContainer: {
      [breakpoints.down("sm")]: {
        maxWidth: "360px",
        maxHeight: "300px",
        flexWrap: "wrap",
      },
    },
    fixed: {
      // [breakpoints.up("md")]: {
      //   paddingTop: "50px",
      // },
    },
    root: {
      "& .Mui-selected.MuiTab-wrapper": {
        backgroundColor: "aqua",
      },
    },
  },
  //to do :change .PrivateTabIndicator-colorSecondary-85
  MuiTab: {
    root: {
      minHeight: "33px",
      lineHeight: 2.2,
      // width: "50%",
      "&$selected": {
        backgroundColor: colors.red,
        color: colors.white,
      },
      padding: 0,
      [breakpoints.up("sm")]: {
        minWidth: "auto",
      },
    },
    wrapper: {
      border: "1px solid #d9d9d9",
      padding: "8px 12px",
      width: "auto",
    },
  },
  MuiPaper: {
    elevation4: {
      boxShadow: "none",
    },
    rounded: {
      // backgroundColor: "transparent",
    },
  },
  MuiBreadcrumbs: {
    separator: {
      color: colors.red,
    },
  },
  MuiDrawer: {
    paper: {
      borderTopLeftRadius: "22px",
      borderBottomLeftRadius: "22px",
      paddingTop: "21px",
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        backgroundColor: colors.transparent,
      },
    },
    icon: {
      color: colors.black,
    },
  },
  MuiDivider: {
    root: {
      height: "2px",
      width: "calc(100% - 60px)",
      margin: "0 auto",
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 6, 21, 0.5)",
      webkitBackdropFilter: "blur(5px)",
      backdropFilter: "blur(5px)",
    },
  },
  MuiContainer: {
    root: {
      paddingRight: "15px",
      paddingLeft: "15px",
      [breakpoints.up("md")]: {
        paddingRight: "100px",
        paddingLeft: "100px",
      },
    },
  },
  MuiInputLabel: {
    root: {
      backgroundColor: "transparent",
      direction: "inherit",
    },
    outlined: {
      transform: "translate(0, 0) scale(1)",
      legend: {
        fontWeight: "bold",
        fontSize: "16px",
      },
      "&$shrink": {
        transform: "translate(15px, -8px) scale(1)",

        [breakpoints.up("md")]: {
          transform: "translate(15px, -10px) scale(1)",
        },
      },
    },
    // formControl: {
    //   top: "-5px",
    //   right: "30px",
    // }
  },
  MuiDialog: {
    //   paperWidthSm: {
    //     borderRadius: 30,
    //     overflow: "hidden",
    //     [breakpoints.up("md")]: {
    //       flexDirection: "row",
    //       maxHeight: "100%",
    //     },
    //   },
    //   paperScrollPaper: {
    //     maxHeight: "calc(100% - 90px)",
    //     overflowY: "initial",
    //   },
    //   paper: {
    //     width: "100%",
    //     margin: "32px 6px;",
    //   },
    //   paperScrollBody: {
    //     overflow: "visible",
    //     margin: "50px 6px;",
    //
    //     [breakpoints.down("sm")]: {
    //       width: "calc(100% - 12px)",
    //       maxWidth: "100% !important",
    //     },
    //   },
    // },
    // MuiDialogContent: {
    //   root: {
    //     borderRadius: "5px",
    //     boxShadow: "0 5px 99px 0 rgba(9, 42, 70, 0.6)",
    //     border: "solid 1px #707070",
    //     backgroundColor: colors.white,
    //   },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: colors.black,
    },
  },
  MuiButton: {
    contained: {
      color: colors.white,
      backgroundColor: colors.red,
      outline: null,
      padding: "8px 0",
      borderRadius: "23px",
      lineHeight: "24px",

      boxShadow: null,
      "&:hover": {
        border: null,
        boxShadow: null,
        backgroundColor: colors.hoverRed,
      },
    },

    // outlined: {
    //   color: colors.oceanBlue,
    //   backgroundColor: colors.white,
    //   outline: null,
    //   padding: null,
    //   boxShadow: null,
    //   border: `1px solid ${colors.oceanBlue}`,
    //   "&:hover": {
    //     color: colors.oceanBlue,
    //     backgroundColor: colors.white,
    //     border: null,
    //     boxShadow: null,
    //   },
    // },
    // outlined: {
    //   borderRadius: "5px",
    //   fontSize: '13px'
    // },
    outlinedSecondary: {
      color: colors.white,
      backgroundColor: "transparent",
      border: `1px solid ${colors.white}`,
      "&:hover": {
        color: colors.white,
        backgroundColor: "transparent",
        border: null,
      },
    },
    sizeSmall: {
      fontSize: "14px",
      [breakpoints.down("sm")]: {
        padding: "5px 0",
      },
    },
    root: {
      borderRadius: 1,
      fontWeight: "bold",
      fontSize: "16px",
      [breakpoints.down("sm")]: {
        // width: "100%",
        padding: "10px 0",
      },
      [breakpoints.up("md")]: {
        padding: "10px 60px",
        // marginTop: "20px",
        fontSize: "20px",
      },
      boxShadow: null,
      "&:hover": {
        boxShadow: null,
      },
    },
    label: {
      textAlign: "center",
    },
    startIcon: {
      marginInlineStart: "-4px",
      marginInlineEnd: "8px",
      marginRight: "0",
      marginLeft: "0",
      //display: inherit;
      //margin-right: -4px;
      //margin-left: 8px;
      //    display: inherit;
      //margin-left: -4px;
      //margin-right: 8px;
    },
  },

  MuiOutlinedInput: {
    input: {
      padding: "12px 22px",
    },
    root: {
      borderRadius: 0,
      "& legend": {
        fontWeight: "normal",
        fontSize: "16px",

        [breakpoints.up("md")]: {
          fontSize: "16px",
        },
      },
      "& $notchedOutline": {
        borderColor: colors.warmGrey,
        borderWidth: 1.5,
      },

      "&:hover $notchedOutline": {
        borderColor: colors.black,
        borderWidth: 1.5,
      },

      "&$focused $notchedOutline": {
        borderColor: colors.black,
        borderWidth: 1.5,
      },

      "&$error $notchedOutline": {
        borderColor: colors.red,
        borderWidth: 1.5,
      },
    },
  },

  MuiFormLabel: {
    root: {
      flexBasis: "100%",
      direction: "inherit",
      color: colors.gray,
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: 1.14,
      [breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: 1.36,
      },

      "&$focused": {
        color: colors.black,
        "& .MuiFormLabel-asterisk": {
          color: colors.red,
        },
      },

      "&$error": {
        color: colors.warmGrey,
      },
    },
    asterisk: {
      marginLeft: "2px",
      verticalAlign: "top",
      color: colors.red,
      [breakpoints.up("md")]: {
        fontSize: "18px",
      },
    },
  },

  MuiFormControlLabel: {
    root: {
      alignItems: "flex-start",
      ".MuiFormGroup-root &": {
        display: "inline-block",
        marginRight: "0",
        marginLeft: "0",
        "& .MuiButtonBase-root": {
          display: "none",
        },
      },
    },
  },

  MuiMenu: {
    paper: {
      background: colors.white,
    },
  },
  MuiMenuItem: {
    root: {
      padding: "10px",
    },
  },
  MuiInput: {
    input: {
      borderRadius: 0,
      height: "44px",
      boxSizing: "border-box",
      fontSize: "16px",
      padding: "0px",
      paddingTop: "12px",
    },
    root: {
      "&$disabled": {
        color: colors.silver,
      },
    },
    formControl: {
      marginTop: "0 !important",
    },
    // underline: {
    //   "&:before": {
    //     borderBottom: 'none'
    //   }
    // }
  },
  MuiInputBase: {
    root: {
      fontSize: "16px",
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: 0,
      fontSize: "12px !important;",
      "&$error": {
        fontSize: "12px !important;",
        color: colors.red,
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: "3px 30px 25px 25px",
      [breakpoints.up("md")]: {
        padding: "3px 50px 50px 40px",
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      padding: "0px",
      "&$expanded": {
        minHeight: "",
      },
    },
    content: {
      "&$expanded": {
        margin: "",
      },
    },
    expandIcon: {
      "&$expanded": {
        transform: "scaleY(-1)",
      },
    },
  },
  MuiInputAdornment: {
    root: {
      color: colors.black,
    },
    positionStart: {
      paddingLeft: "20px",
      paddingRight: "20px",
      [breakpoints.up("xs")]: {
        paddingRight: "10px",
      },
    },
  },
  MuiIconButton: {
    root: {
      padding: 0,
    },
    label: {
      height: "100%",
    },
  },
  MuiCard: {
    root: {
      boxShadow: "none",
      border: `1px solid`,
      borderColor: colors.activitiesBorder,
    },
  },
  MuiCardContent: {
    root: {
      padding: "15px",
    },
  },
  MuiListItem: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    gutters: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  MuiCheckbox: {
    root: {
      color: colors.black,
    },
    colorPrimary: {
      "&.Mui-checked": {
        color: colors.red,
      },
    },
    colorSecondary: {
      "&$checked": {
        color: colors.red,
      },
    },
  },
}
muiThemeOptions.overrides = overrides

const theme = {
  ...createTheme(muiThemeOptions, heIL),
  colors,
}

export default theme

export type Theme = typeof theme
