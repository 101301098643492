import { createGlobalStyle, css } from "styled-components"

const hebrewFonts = css`
  @font-face {
    font-family: FbJabutinski;
    src: local(FbJabutinski-Light),
      url(/fonts/FbJabutinski-Light.woff2) format("woff2"),
      url(/fonts/FbJabutinski-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinski;
    src: local(FbJabutinski-Regular),
      url(/fonts/FbJabutinski-Regular.woff2) format("woff2"),
      url(/fonts/FbJabutinski-Regular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinski;
    src: local(FbJabutinski-Medium),
      url(/fonts/FbJabutinski-Medium.woff2) format("woff2"),
      url(/fonts/FbJabutinski-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinski-Con;
    src: local(FbJabutinski-ConLight),
      url(/fonts/FbJabutinski-ConLight.woff2) format("woff2"),
      url(/fonts/FbJabutinski-ConLight.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
  @font-face {
    font-family: FbJabutinski-Con;
    src: local(FbJabutinski-ConRegular),
      url(/fonts/FbJabutinski-ConRegular.woff2) format("woff2"),
      url(/fonts/FbJabutinski-ConRegular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinski-Con;
    src: local(FbJabutinski-ConBold),
      url(/fonts/FbJabutinski-ConBold.woff2) format("woff2"),
      url(/fonts/FbJabutinski-ConBold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
`
const englishFonts = css`
  @font-face {
    font-family: FbJabutinskiEng;
    src: local(FbJabutinskiEng-Light),
      url(/fonts/FbJabutinskiEng-Light.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinskiEng;
    src: local(FbJabutinskiEng-Regular),
      url(/fonts/FbJabutinskiEng-Regular.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-Regular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinskiEng;
    src: local(FbJabutinskiEng-Medium),
      url(/fonts/FbJabutinskiEng-Medium.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
  @font-face {
    font-family: FbJabutinskiEng-Con;
    src: local(FbJabutinskiEng-ConLight),
      url(/fonts/FbJabutinskiEng-ConLight.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-ConLight.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
  @font-face {
    font-family: FbJabutinskiEng-Con;
    src: local(FbJabutinskiEng-ConRegular),
      url(/fonts/FbJabutinskiEng-ConRegular.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-ConRegular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: FbJabutinskiEng-Con;
    src: local(FbJabutinskiEng-ConBold),
      url(/fonts/FbJabutinskiEng-ConBold.woff2) format("woff2"),
      url(/fonts/FbJabutinskiEng-ConBold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    src: local("Century Gothic"), url(/fonts/GOTHIC.woff2) format("woff2");
    font-display: swap;
    unicode-range: U+0041-007F;
  }
  @font-face {
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/GOTHIC-BOLD.woff2) format("woff2");
    font-display: swap;
    unicode-range: U+0041-007F;
  }
`

const GlobalStyle = createGlobalStyle`
  ${hebrewFonts}
  ${englishFonts}
  :root {
    direction: rtl;
    font-family: "FbJabutinskiEng","FbJabutinski", sans-serif;

    &:not([lang="he"]) {
      direction: ltr;
    }
  }

  body {
    overflow-x: hidden;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  *:focus-visible{
    border: 1px dashed white;
    outline-offset: 2px;
    outline: 2px solid black;
  }

  input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
`
export const fontCondensed = css`
  font-family: "FbJabutinskiEng-Con", "FbJabutinski-Con", sans-serif;
`
export const fontRegular = css`
  font-family: "FbJabutinskiEng", "FbJabutinski", sans-serif;
`

export default GlobalStyle
