import googleTagManager from "@analytics/google-tag-manager"
import Analytics from "analytics"

// EXAMPLE FOR FB/AW/ANALYTICS
//
// declare global {
//   interface Window {
//     gtag?: any
//     dataLayer?: any
//   }
// }

// export const FB_PIXEL_ID = process.env.FB_PIXEL_ID
// export const AW_CONVERSION_ID = process.env.AW_CONVERSION_ID
// export const ANALYTICS_ID = process.env.ANALYTICS_ID
// let gtag
// if (!AW_CONVERSION_ID) {
//   throw new Error("Please provide the AW_CONVERSION_ID environment variable.")
// }

// if (!ANALYTICS_ID) {
//   throw new Error("Please provide the ANALYTICS_ID environment variable.")
// }

// if (!FB_PIXEL_ID) {
//   throw new Error("Please provide the FB_PIXEL_ID environment variable.")
// }

// export const initAnalytics = () => {
//   if (window) {
//     gtag = window?.gtag
//   }
// }
// export const fbPageview = () => {
//   window.fbq("track", "PageView")
//   if (gtag) {
//     gtag("send", "pageview", location?.pathname)
//   }
// }

// const fbEvent = (name: string, options: any = {}) => {
//   window.fbq("track", name, options)
// }

// const gtagEvent = (name: string) => {
//   if (gtag) {
//     gtag("event", "conversion", { send_to: name })
//   }
// }
// const analyticsEvent = (name: string) => {
//   if (gtag) {
//     gtag("event", name)
//   }
// }

// export const trackCouponCode = () => {
//   gtagEvent(`${AW_CONVERSION_ID}/Ve9qCOry36UCEPPY45kD`)
//   fbEvent("ClientCouponExposure")
//   analyticsEvent("ClientCouponExposure")
// }

// END EXAMPLE

const GTM_ID = process.env.GTM_ID

if (!GTM_ID) {
  throw new Error("Please provide the GTM_ID environment variable.")
}

/* Initialize analytics & load plugins */
let analytics
export const initAnalytics = () => {
  analytics = Analytics({
    app: "Amot",
    plugins: [googleTagManager({ containerId: GTM_ID })],
  })
}

export const trackPage = () => {
  analytics?.page()
}

// export const trackTechnicalSpecificationExpand = (data: {
//   stepName: string
//   buttonText: string
// }) =>
//   analytics.track("technical_specification_expand", {
//     step: data.stepName,
//     model: "geometry c",
//     text: data.buttonText,
//   })
