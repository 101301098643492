import { Button, Snackbar, Typography } from "@material-ui/core"
import NextLink from "common/NextLink"
import { useTranslations } from "next-intl"
import React, { useState } from "react"
import styled from "styled-components"
const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: start;
  }
`

const StyledSnackbar = styled(Snackbar)`
  .MuiPaper-root {
    background-color: ${(p) => p.theme.colors.white};
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
    max-width: 700px;
  }

  .MuiSnackbarContent-message {
    display: none;
  }

  .MuiSnackbarContent-action {
    padding: 0px;
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    left: 16px;
    right: 16px;
    bottom: 16px;
  }
`
const StyledButton = styled(Button)`
  width: 138px;
  height: 32px;
  border-radius: 5px;
  border: solid 1.5px ${(p) => p.theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 10px;
  }
`
const StyledText = styled(Typography).attrs({ variant: "body1" })`
  font-size: 12px;
  color: ${(p) => p.theme.colors.gray};
  width: 100%;
  a {
    text-decoration: underline;
  }
`
const cookiesApproved = "cookiesApproved"
export const getCookiesApproved = () => localStorage.getItem(cookiesApproved)
export const setCookiesApproved = (name: string) =>
  localStorage.setItem(cookiesApproved, name)
export const clearCookiesApproved = () =>
  localStorage.removeItem(cookiesApproved)

const Cookies = () => {
  const t = useTranslations("cookie")
  const [open, setOpen] = useState(false)

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    setCookiesApproved("true")
    setOpen(false)
  }

  React.useEffect(() => {
    const localCookies = getCookiesApproved()
    if (!localCookies) {
      setOpen(true)
    }
  }, [])

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
      action={
        <StyledWrapper>
          <StyledText>
            {`${t("text")}  `}
            <NextLink href="/privacy-policy">{t("linkText")}</NextLink>
          </StyledText>
          <StyledButton onClick={handleClose}>{t("buttonText")}</StyledButton>
        </StyledWrapper>
      }
    />
  )
}

export default Cookies
