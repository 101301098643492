/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://apiwebsite.amot.co.il/swagger/v1/swagger.json **/

import axios from "../axios"
import * as Amot from "./AmotInterfaces"

export const getAbout = (siteId: string /* uuid */) =>
  axios.get<Amot.AboutPage>(`/api/About`, { params: { siteId }, headers: {} })

export const getAboutId = (id: string) =>
  axios.get<Amot.AboutPage>(`/api/About/${id}`, { headers: {} })

export const getAboutSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.AboutPage>(`/api/About/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const postAdminFillCache = () =>
  axios.post<any>(`/api/Admin/fillCache`, { headers: {} })

export const getAdminCropMediaUrlIdWidthHeight = (
  id: string,
  width: string,
  height: string
) =>
  axios.get<any>(`/api/Admin/cropMediaUrl/${id},${width},${height}`, {
    headers: {},
  })

export const getAdminVersion = () =>
  axios.get<any>(`/api/Admin/version`, { headers: {} })

export const getAdminCheckSlugSlug = (slug: string) =>
  axios.get<any>(`/api/Admin/checkSlug/${slug}`, { headers: {} })

export const getAmot360 = (siteId: string /* uuid */) =>
  axios.get<Amot.Amot360Page>(`/api/Amot360`, {
    params: { siteId },
    headers: {},
  })

export const getAmot360Id = (id: string) =>
  axios.get<Amot.Amot360Page>(`/api/Amot360/${id}`, { headers: {} })

export const getAmot360Slug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.Amot360Page>(`/api/Amot360/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getAmotConstructionLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.AmotConstructionPageBaseAssetLobby>(
    `/api/AmotConstruction/lobby`,
    { params: { siteId }, headers: {} }
  )

export const getAmotConstructionLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/AmotConstruction/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getAmotConstructionLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/AmotConstruction/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getAmotConstructionSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage[]>(
    `/api/AmotConstruction/search/${searchParam}`,
    { params: { searchParam, siteId }, headers: {} }
  )

export const getAmotConstructionFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage[]>(
    `/api/AmotConstruction/filterByArea/${areaName}`,
    { params: { siteId }, headers: {} }
  )

export const getAmotConstructionFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage[]>(
    `/api/AmotConstruction/filterByCity/${cityName}`,
    { params: { siteId }, headers: {} }
  )

export const getAmotConstructionSimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage[]>(
    `/api/AmotConstruction/similarAssets/${slug}`,
    { params: { siteId }, headers: {} }
  )

export const getAmotConstructionListCount = (
  count: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage[]>(
    `/api/AmotConstruction/list/${count}`,
    { params: { siteId }, headers: {} }
  )

export const getAmotConstruction = (siteId: string /* uuid */) =>
  axios.get<Amot.AmotConstructionPage>(`/api/AmotConstruction`, {
    params: { siteId },
    headers: {},
  })

export const getAmotConstructionId = (id: string) =>
  axios.get<Amot.AmotConstructionPage>(`/api/AmotConstruction/${id}`, {
    headers: {},
  })

export const getAmotConstructionSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.AmotConstructionPage>(`/api/AmotConstruction/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getArticleSlugList = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Article/slugList`, {
    params: { siteId },
    headers: {},
  })

export const getArticle = (siteId: string /* uuid */) =>
  axios.get<Amot.ArticlePage>(`/api/Article`, {
    params: { siteId },
    headers: {},
  })

export const getArticleId = (id: string) =>
  axios.get<Amot.ArticlePage>(`/api/Article/${id}`, { headers: {} })

export const getArticleSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.ArticlePage>(`/api/Article/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getBlog = (siteId: string /* uuid */) =>
  axios.get<Amot.BlogPage>(`/api/Blog`, { params: { siteId }, headers: {} })

export const getBlogId = (id: string) =>
  axios.get<Amot.BlogPage>(`/api/Blog/${id}`, { headers: {} })

export const getBlogSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.BlogPage>(`/api/Blog/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getBoard = (siteId: string /* uuid */) =>
  axios.get<Amot.BoardPage>(`/api/Board`, { params: { siteId }, headers: {} })

export const getBoardId = (id: string) =>
  axios.get<Amot.BoardPage>(`/api/Board/${id}`, { headers: {} })

export const getBoardSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.BoardPage>(`/api/Board/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getCommonLobbyEcho = () =>
  axios.get<string>(`/api/Common/lobby-echo`, { headers: {} })

export const getCommonLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.CommonLobbyPage>(`/api/Common/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getCommonLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Common/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getCommonLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Common/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getCommonSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.CommonLobbyPageModel[]>(`/api/Common/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getCommonFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.CommonLobbyPageModel[]>(
    `/api/Common/filterByArea/${areaName}`,
    { params: { siteId }, headers: {} }
  )

export const getCommonFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.CommonLobbyPageModel[]>(
    `/api/Common/filterByCity/${cityName}`,
    { params: { siteId }, headers: {} }
  )

export const getCommonListCount = (count: string, siteId: string /* uuid */) =>
  axios.get<Amot.CommonLobbyPageModel[]>(`/api/Common/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const postContactUsSendSubmission = (
  FirstName: string,
  LastName: string,
  Mobile: string,
  Email: string,
  Company: string,
  ApproveMarketing: boolean,
  WebSiteUtm: string,
  LandingPageName: string
) =>
  axios.post<Amot.HttpResponseMessage>(`/api/ContactUs/SendSubmission`, {
    params: {
      FirstName,
      LastName,
      Mobile,
      Email,
      Company,
      ApproveMarketing,
      WebSiteUtm,
      LandingPageName,
    },
    headers: {},
  })

export const getContactUs = (siteId: string /* uuid */) =>
  axios.get<Amot.ContactPage>(`/api/ContactUs`, {
    params: { siteId },
    headers: {},
  })

export const getContactUsId = (id: string) =>
  axios.get<Amot.ContactPage>(`/api/ContactUs/${id}`, { headers: {} })

export const getContactUsSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.ContactPage>(`/api/ContactUs/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getDividents = (siteId: string /* uuid */) =>
  axios.get<Amot.DividentPage>(`/api/Dividents`, {
    params: { siteId },
    headers: {},
  })

export const getDividentsId = (id: string) =>
  axios.get<Amot.DividentPage>(`/api/Dividents/${id}`, { headers: {} })

export const getDividentsSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.DividentPage>(`/api/Dividents/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getGeneralContent = (siteId: string /* uuid */) =>
  axios.get<Amot.GeneralContentPage>(`/api/GeneralContent`, {
    params: { siteId },
    headers: {},
  })

export const getGeneralContentId = (id: string) =>
  axios.get<Amot.GeneralContentPage>(`/api/GeneralContent/${id}`, {
    headers: {},
  })

export const getGeneralContentSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.GeneralContentPage>(`/api/GeneralContent/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getHome = (siteId: string /* uuid */) =>
  axios.get<Amot.HomePage>(`/api/Home`, { params: { siteId }, headers: {} })

export const getHomeId = (id: string) =>
  axios.get<Amot.HomePage>(`/api/Home/${id}`, { headers: {} })

export const getHomeSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.HomePage>(`/api/Home/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.IndustryPageBaseAssetLobby>(`/api/Industry/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Industry/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Industry/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getIndustrySearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.IndustryPage[]>(`/api/Industry/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getIndustryFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.IndustryPage[]>(`/api/Industry/filterByArea/${areaName}`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.IndustryPage[]>(`/api/Industry/filterByCity/${cityName}`, {
    params: { siteId },
    headers: {},
  })

export const getIndustrySimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.IndustryPage[]>(`/api/Industry/similarAssets/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryListCount = (
  count: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.IndustryPage[]>(`/api/Industry/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getIndustry = (siteId: string /* uuid */) =>
  axios.get<Amot.IndustryPage>(`/api/Industry`, {
    params: { siteId },
    headers: {},
  })

export const getIndustryId = (id: string) =>
  axios.get<Amot.IndustryPage>(`/api/Industry/${id}`, { headers: {} })

export const getIndustrySlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.IndustryPage>(`/api/Industry/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.InitiationPageBaseAssetLobby>(`/api/Initiation/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Initiation/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Initiation/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InitiationPage[]>(`/api/Initiation/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getInitiationFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InitiationPage[]>(`/api/Initiation/filterByArea/${areaName}`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InitiationPage[]>(`/api/Initiation/filterByCity/${cityName}`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationSimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InitiationPage[]>(`/api/Initiation/similarAssets/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationListCount = (
  count: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InitiationPage[]>(`/api/Initiation/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getInitiation = (siteId: string /* uuid */) =>
  axios.get<Amot.InitiationPage>(`/api/Initiation`, {
    params: { siteId },
    headers: {},
  })

export const getInitiationId = (id: string) =>
  axios.get<Amot.InitiationPage>(`/api/Initiation/${id}`, { headers: {} })

export const getInitiationSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.InitiationPage>(`/api/Initiation/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getInvestorsLastLoadedReportsCount = (
  count: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.InvestorsPage[]>(`/api/Investors/lastLoadedReports/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getInvestorsFilteredReports = (
  dateFrom: string /* date-time */,
  dateTo: string /* date-time */,
  siteId: string /* uuid */
) =>
  axios.get<Amot.BaseReport[]>(`/api/Investors/filteredReports`, {
    params: { dateFrom, dateTo, siteId },
    headers: {},
  })

export const getInvestors = (siteId: string /* uuid */) =>
  axios.get<Amot.InvestorsPage>(`/api/Investors`, {
    params: { siteId },
    headers: {},
  })

export const getInvestorsId = (id: string) =>
  axios.get<Amot.InvestorsPage>(`/api/Investors/${id}`, { headers: {} })

export const getInvestorsSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.InvestorsPage>(`/api/Investors/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getMallLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.MallPageBaseAssetLobby>(`/api/Mall/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getMallLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Mall/lobby/v2`, { params: { siteId }, headers: {} })

export const getMallLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Mall/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getMallSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.MallPage[]>(`/api/Mall/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getMallFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.MallPage[]>(`/api/Mall/filterByArea/${areaName}`, {
    params: { siteId },
    headers: {},
  })

export const getMallFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.MallPage[]>(`/api/Mall/filterByCity/${cityName}`, {
    params: { siteId },
    headers: {},
  })

export const getMallSimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.MallPage[]>(`/api/Mall/similarAssets/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getMallListCount = (count: string, siteId: string /* uuid */) =>
  axios.get<Amot.MallPage[]>(`/api/Mall/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getMall = (siteId: string /* uuid */) =>
  axios.get<Amot.MallPage>(`/api/Mall`, { params: { siteId }, headers: {} })

export const getMallId = (id: string) =>
  axios.get<Amot.MallPage>(`/api/Mall/${id}`, { headers: {} })

export const getMallSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.MallPage>(`/api/Mall/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getManagement = (siteId: string /* uuid */) =>
  axios.get<Amot.ManagementPage>(`/api/Management`, {
    params: { siteId },
    headers: {},
  })

export const getManagementId = (id: string) =>
  axios.get<Amot.ManagementPage>(`/api/Management/${id}`, { headers: {} })

export const getManagementSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.ManagementPage>(`/api/Management/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.OfficePageBaseAssetLobby>(`/api/Office/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Office/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Office/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.OfficePage[]>(`/api/Office/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getOfficeFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.OfficePage[]>(`/api/Office/filterByArea/${areaName}`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.OfficePage[]>(`/api/Office/filterByCity/${cityName}`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeSimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.OfficePage[]>(`/api/Office/similarAssets/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getOfficeListCount = (count: string, siteId: string /* uuid */) =>
  axios.get<Amot.OfficePage[]>(`/api/Office/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getOffice = (siteId: string /* uuid */) =>
  axios.get<Amot.OfficePage>(`/api/Office`, { params: { siteId }, headers: {} })

export const getOfficeId = (id: string) =>
  axios.get<Amot.OfficePage>(`/api/Office/${id}`, { headers: {} })

export const getOfficeSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.OfficePage>(`/api/Office/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getPage = (siteId: string /* uuid */) =>
  axios.get<Amot.DynamicPage>(`/api/Page`, { params: { siteId }, headers: {} })

export const getPageId = (id: string) =>
  axios.get<Amot.DynamicPage>(`/api/Page/${id}`, { headers: {} })

export const getPageSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.DynamicPage>(`/api/Page/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getShareholder = (siteId: string /* uuid */) =>
  axios.get<Amot.ShareholderPage>(`/api/Shareholder`, {
    params: { siteId },
    headers: {},
  })

export const getShareholderId = (id: string) =>
  axios.get<Amot.ShareholderPage>(`/api/Shareholder/${id}`, { headers: {} })

export const getShareholderSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.ShareholderPage>(`/api/Shareholder/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getSites = () =>
  axios.get<Amot.Site[]>(`/api/Sites`, { headers: {} })

export const getSitesSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.GlobalSiteSearchModel[]>(`/api/Sites/Search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getSupermarketLobby = (siteId: string /* uuid */) =>
  axios.get<Amot.SupermarketPageBaseAssetLobby>(`/api/Supermarket/lobby`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarketLobbyV2 = (siteId: string /* uuid */) =>
  axios.get<string[]>(`/api/Supermarket/lobby/v2`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarketLobbyV3 = (siteId: string /* uuid */) =>
  axios.get<Amot.AssetLobbyShortModel>(`/api/Supermarket/lobby/v3`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarketSearchSearchParam = (
  searchParam: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.SupermarketPage[]>(`/api/Supermarket/search/${searchParam}`, {
    params: { searchParam, siteId },
    headers: {},
  })

export const getSupermarketFilterByAreaAreaName = (
  areaName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.SupermarketPage[]>(
    `/api/Supermarket/filterByArea/${areaName}`,
    { params: { siteId }, headers: {} }
  )

export const getSupermarketFilterByCityCityName = (
  cityName: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.SupermarketPage[]>(
    `/api/Supermarket/filterByCity/${cityName}`,
    { params: { siteId }, headers: {} }
  )

export const getSupermarketSimilarAssetsSlug = (
  slug: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.SupermarketPage[]>(`/api/Supermarket/similarAssets/${slug}`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarketListCount = (
  count: string,
  siteId: string /* uuid */
) =>
  axios.get<Amot.SupermarketPage[]>(`/api/Supermarket/list/${count}`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarket = (siteId: string /* uuid */) =>
  axios.get<Amot.SupermarketPage>(`/api/Supermarket`, {
    params: { siteId },
    headers: {},
  })

export const getSupermarketId = (id: string) =>
  axios.get<Amot.SupermarketPage>(`/api/Supermarket/${id}`, { headers: {} })

export const getSupermarketSlug = (slug: string, siteId: string /* uuid */) =>
  axios.get<Amot.SupermarketPage>(`/api/Supermarket/${slug}`, {
    params: { siteId },
    headers: {},
  })
