export const MainRoutes = {
  lobbyPage: "/",
  assetsList: {
    allAssets: "/assets-list/all-assets",
    offices: "/assets-list/offices",
    shoppingMalls: "/assets-list/shopping-mall",
    industry: "/assets-list/industry",
    projectPlan: "/assets-list/in-progress",
    supermarkets: "/assets-list/supermarkets",
  },
  investments: {
    aboutAmot: "/about/about-us",
    companyManagement: "/about/management",
    boardOfDirectors: "/about/board-of-directors",
    sustainability: "/about/sustainability",
    accessibilityStatement: "/about/accessibility-statement",
    magazine: "/#magazine",
  },
  relations: {
    main: "/investor-relations",
    shareholders: "/investor-relations/shareholders",
    dividends: "/investor-relations/dividends",
  },
  general: {
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",
    contact: "/contact-us",
    accessibilityStatement: "/about/accessibility-statement",
    // serviceLevel: "/service-level-agreement",
    siteMap: "/site-map",
  },
  extraButtons: {
    assetsList: "/assets-list/offices",
    investments: "/about/about-us",
    relations: "",
  },
  amot360: {
    amot360: "/amot360",
  },
}
