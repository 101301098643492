import { CssBaseline } from "@material-ui/core"
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles"
import { create } from "jss"
import rtl from "jss-rtl"
import type { FC } from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "./GlobalStyle"
import theme from "./theme"

const jss = (locale: string) =>
  create({
    plugins: [...jssPreset().plugins, locale == "he" ? rtl() : null],
    insertionPoint:
      typeof window !== "undefined"
        ? (document.querySelector("#jss-insertion-point") as HTMLElement)
        : undefined,
  })

const ThemeProviders: FC<{ locale: string }> = ({ locale, children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss(locale)}>
        <CssBaseline />
        <GlobalStyle />

        {children}
      </StylesProvider>
    </ThemeProvider>
  </MuiThemeProvider>
)

export default ThemeProviders
