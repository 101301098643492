import { Box, ButtonBase } from "@material-ui/core"
import NextLink from "common/NextLink"
import ContactUsPopup from "components/contact-us/ContactUsPopup"
import { useLocale } from "next-intl"
import React, { useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import {
  StyledAnimationFade,
  StyledAnimationScale,
} from "../../src/styles/index"
// import { Transition, CSSTransition } from 'react-transition-group';

//Styles
const StyledSome = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`
const StyledContainer = styled(Box)`
  position: fixed;
  left: 40px;
  bottom: 50px;
  min-width: 0;

  padding: 0;
  justify-content: end;

  z-index: 1001;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    left: 16px;
    bottom: 15px;
  }

  &:hover {
    background-color: ${colors.transparent};
  }
`
const StyledRedIcon = styled.img`
  z-index: 2;

  &:active {
    transform: scale(0.95);
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  ${StyledAnimationFade}
`
const StyledActionContainer = styled.div<{ $isOpenMenu: boolean }>`
  position: absolute;
  top: -172.5px;

  display: flex;
  flex-direction: column;
  visibility: ${(p) => (p.$isOpenMenu ? "visible" : "hidden")};

  transform: ${(p) =>
    p.$isOpenMenu ? "translate(0)" : "translate(0px,1000px)"};
`

export const ContactUsButton: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const locale = useLocale()

  //Handlers
  const handleToggleMenu = () => {
    setIsOpenMenu((prev) => !prev)
  }
  const handleToggleDialog = (): void => {
    setIsDialogOpen((prev) => !prev)
  }

  //Render
  return (
    <>
      {isOpenMenu && (
        <StyledSome onClick={isOpenMenu ? handleToggleMenu : null} />
      )}
      <StyledContainer>
        <ButtonBase
          onClick={handleToggleMenu}
          tabIndex="0"
          aria-expanded={isOpenMenu}
        >
          {isOpenMenu ? (
            <StyledRedIcon
              aria-expanded="true"
              alt="סגור רכיב יצירת קשר"
              src="/closeButton.svg"
              isStart={isOpenMenu}
              animationTime={600}
            />
          ) : (
            <StyledRedIcon
              src="/contactUsIcon.svg"
              aria-expanded="false"
              alt="דרכי יצירת קשר"
              isStart={!isOpenMenu}
            />
          )}
        </ButtonBase>
        <StyledActionContainer
          $isOpenMenu={isOpenMenu}
          role={isOpenMenu ? null : "presentation"}
          aria-hidden={isOpenMenu ? "false" : "true"}
        >
          <NextLink href={`https://wa.me/9720528776684`} passHref>
            <ButtonBase
              href=""
              target="_blank"
              aria-label="צור קשר בוואטסאפ"
              role={isOpenMenu ? null : "presentation"}
              aria-hidden={isOpenMenu ? "false" : "true"}
            >
              <WhiteIcon isOpenMenu={isOpenMenu} index={2}>
                <StyledWhiteIcon src="/whatsappIcon.svg" alt="" />
              </WhiteIcon>
            </ButtonBase>
          </NextLink>

          <NextLink href="tel:*2668" passHref>
            <ButtonBase
              href=""
              target="_blank"
              aria-label="התקשר אלינו"
              role={isOpenMenu ? null : "presentation"}
              aria-hidden={isOpenMenu ? "false" : "true"}
            >
              <WhiteIcon isOpenMenu={isOpenMenu} index={2}>
                <StyledWhiteIcon src="/Phone.svg" alt="" />
              </WhiteIcon>
            </ButtonBase>
          </NextLink>
          <ButtonBase
            onClick={handleToggleDialog}
            aria-label="פתח חלונית טופס צור קשר"
            role={isOpenMenu ? null : "presentation"}
            aria-hidden={isOpenMenu ? "false" : "true"}
          >
            <WhiteIcon isOpenMenu={isOpenMenu} index={isOpenMenu ? 3 : 1}>
              <StyledWhiteIcon src="/fileIcon.svg" alt="" />
            </WhiteIcon>
          </ButtonBase>
        </StyledActionContainer>
        {/* )} */}
        <ContactUsPopup open={isDialogOpen} close={handleToggleDialog} />
      </StyledContainer>
    </>
  )
}

//Types
type WhiteIconProps = {
  isOpenMenu: boolean
  index: number
}

const StyledWhiteIconContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  background-color: ${colors.alabaster};
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);

  ${StyledAnimationScale}
`

const StyledWhiteIcon = styled.img``

const WhiteIcon: React.FC<WhiteIconProps> = ({
  isOpenMenu,
  index,
  children,
}) => {
  //Render
  return (
    <StyledWhiteIconContainer
      isStart={isOpenMenu}
      animationTimeout={index * 150}
    >
      {children}
    </StyledWhiteIconContainer>
  )
}
