import Cookies from "components/cookies/Cookies"
import { LogoJsonLd, SiteLinksSearchBoxJsonLd } from "next-seo"
import dynamic from "next/dynamic"
import { FC, useRef } from "react"
import styled from "styled-components"
import { ContactUsButton } from "./components/ContactUsButton"

const Header = dynamic(() => import("./Header"), {
  loading: () => <p>...</p>,
})

const Footer = dynamic(() => import("./Footer"), {
  loading: () => <p>...</p>,
})

const StyledMain = styled.main`
  flex-grow: 1;
  padding-top: 60px;

  /*
  overflow: hidden; */

  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
  }

  :focus {
    outline: 0;
  }
`

const Layout: FC<any> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const siteUrl: string = "https://www.amot.co.il"

  return (
    <>
      <LogoJsonLd logo="/Logonew2x.png" url={siteUrl} />
      <SiteLinksSearchBoxJsonLd
        url={siteUrl}
        potentialActions={[
          {
            target: "/search?searchText=",
            queryInput: "search_term_string",
          },
        ]}
      />
      <Header />
      <Cookies />

      <>
        <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
          {children}
        </StyledMain>
        <ContactUsButton />
        <Footer />
      </>
    </>
  )
}

export default Layout
