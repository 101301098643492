import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { initAnalytics, trackPage } from "common/analytics"
import { AssetSearchProvider } from "context/AssetSearchContext"
import { LayoutProvider } from "context/LayoutContext"
import Layout from "layout/Layout"
import { IntlErrorCode, NextIntlProvider } from "next-intl"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import dynamic from "next/dynamic"
// import dynamic from "next/dynamic"
import { Router, useRouter } from "next/router"
import { useEffect } from "react"
import "swiper/css/bundle"
import ThemeProviders from "theme/ThemeProviders"

const AccessibilityScript = dynamic(
  () => import("components/Script/AccessibilityScript"),
  {
    ssr: true,
  }
)

const ORIGIN = process.env.ORIGIN
if (!ORIGIN) {
  throw new Error("Please provide the ORIGIN environment variable.")
}

const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY

Router.events.on("routeChangeComplete", () => {
  trackPage()
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)

    // Initialize Azure Application Insights
    if (
      window &&
      AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY &&
      !window.appInsights
    ) {
      window.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
          maxBatchSizeInBytes: 10000,
          maxBatchInterval: 15000,
        },
      })
      window.appInsights.loadAppInsights()
      window.appInsights.trackPageView()
    }
    if (window) {
      setTimeout(() => {
        initAnalytics()
        trackPage()
      }, 0)
    }
  }, [])

  const { asPath, locale } = useRouter()
  const url = (ORIGIN + asPath).split(/[?#]/)[0] // remove query string & hash
  useEffect(() => {
    document.body.dir = locale === "he" ? "rtl" : "ltr"
  }, [locale])

  return (
    <>
      <DefaultSeo
        titleTemplate="%s - Amot"
        canonical={url}
        openGraph={{
          site_name: "Amot",
          locale: "he_IL",
          type: "website",
          url,
        }}
      />

      <ThemeProviders locale={locale}>
        <NextIntlProvider
          formats={{
            dateTime: {
              short: {
                day: "numeric",
                month: "short",
                year: "numeric",
              },
            },
          }}
          messages={(pageProps as any)?.messages ?? {}}
          now={new Date((pageProps as any).now)}
          timeZone="Israel/Jerusalem"
          onError={(err) => {
            if (err.code === IntlErrorCode.MISSING_MESSAGE) {
              console.warn("Missing translation", err.message)
              return
            }
            throw err
          }}
        >
          <AssetSearchProvider>
            <LayoutProvider>
              {asPath.includes("contact-us-form") ? (
                <>
                  <Component {...pageProps} />
                </>
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
            </LayoutProvider>
          </AssetSearchProvider>
        </NextIntlProvider>
      </ThemeProviders>
      <AccessibilityScript />
    </>
  )
}

export default MyApp
