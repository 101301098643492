import { Category } from "api"
import { UseSiteId } from "api/SiteId"
import {
  AssetListType,
  AssetType,
  getAssetFilterByCityByType,
  getAssetsByType,
  getAssetsFilterByAreaByType,
  getSearchAssetsByType,
} from "common/AssetType"
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react"

type MapOrListType = "map" | "list" | ""

export type AreaOrCityLobby = "area" | "city" | ""

// type for all results. a dictionary of AssetType key and AssetTypeList value
type AllResultsType = {
  [key in AssetType]: AssetListType[]
}

type SearchContextType = {
  popUpIsOpen: boolean
  setPopUpIsOpen: Dispatch<SetStateAction<boolean>>
  openMapOrList: MapOrListType
  setOpenMapOrList: Dispatch<SetStateAction<MapOrListType>>
  selectedAssetsResults: AssetListType[]
  setSelectedAssetsResult: Dispatch<SetStateAction<AssetListType[]>>
  fetchAssets: (
    query: string,
    type?: AreaOrCityLobby | undefined,
    categoryType?: AssetType
  ) => Promise<AssetListType[]>
  activeCategoryType: AssetType
  setActiveCategoryType: Dispatch<SetStateAction<AssetType>>
  homePageDataCategories: Category[]
  setHomePageDataCategories: Dispatch<SetStateAction<Category[]>>
  openCategoriesDrawer: boolean
  setOpenCategoriesDrawer: Dispatch<SetStateAction<boolean>>
  setIsChanging: Dispatch<SetStateAction<boolean>>
  isChanging: boolean
  areaOrCityLobby: AreaOrCityLobby
  setAreaOrCityLobby: Dispatch<SetStateAction<AreaOrCityLobby>>
  showAllResults: () => void
  closeAssetSearch: () => void
  setValue: Dispatch<SetStateAction<string>>
  value: string
}

const SearchContext = createContext<SearchContextType>(null!)

export const AssetSearchProvider: React.FC = ({ children }) => {
  const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false)
  const [openMapOrList, setOpenMapOrList] = useState<MapOrListType>("")
  const [selectedAssetsResults, setSelectedAssetsResult] = useState<
    AssetListType[]
  >([])
  const [activeCategoryType, setActiveCategoryType] =
    useState<AssetType>("all-assets")
  const [homePageDataCategories, setHomePageDataCategories] =
    useState<Category[]>()
  const [openCategoriesDrawer, setOpenCategoriesDrawer] =
    useState<boolean>(false)
  const [isChanging, setIsChanging] = useState<boolean>(false)

  const [areaOrCityLobby, setAreaOrCityLobby] = useState<AreaOrCityLobby>("")
  const [allResults, setAllResults] = useState<AllResultsType>(null)
  const [value, setValue] = useState("")

  const siteId = UseSiteId()

  const fetchAssets = async (
    query: string,
    type?: AreaOrCityLobby,
    categoryType?: AssetType
  ): Promise<AssetListType[]> => {
    const assetSearch =
      type === "area"
        ? getAssetsFilterByAreaByType(categoryType ?? activeCategoryType)
        : type === "city"
        ? getAssetFilterByCityByType(categoryType ?? activeCategoryType)
        : getSearchAssetsByType(categoryType ?? activeCategoryType)

    const results = await assetSearch(query, siteId)
    return results.data
  }
  const showAllResults = async () => {
    if (allResults && allResults[activeCategoryType]) {
      setSelectedAssetsResult(allResults[activeCategoryType])
      return
    }

    const getAllAssets = getAssetsByType(activeCategoryType)
    const getAllAssetsPromise = await getAllAssets("-1", siteId)
    setSelectedAssetsResult(getAllAssetsPromise.data)
    setAllResults({
      ...allResults,
      [activeCategoryType]: getAllAssetsPromise.data,
    })
  }

  const closeAssetSearch = () => {
    setPopUpIsOpen(false)
    setSelectedAssetsResult([])
    setOpenMapOrList("")
    setOpenCategoriesDrawer(false)
  }

  return (
    <SearchContext.Provider
      value={{
        popUpIsOpen,
        setPopUpIsOpen,
        openMapOrList,
        setOpenMapOrList,
        selectedAssetsResults,
        setSelectedAssetsResult,
        fetchAssets,
        activeCategoryType,
        setActiveCategoryType,
        homePageDataCategories,
        setHomePageDataCategories,
        openCategoriesDrawer,
        setOpenCategoriesDrawer,
        isChanging,
        setIsChanging,
        areaOrCityLobby,
        setAreaOrCityLobby,
        showAllResults,
        closeAssetSearch,
        value,
        setValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useAssetSearchContext = () => useContext(SearchContext)
