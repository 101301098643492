import { strContains } from "@microsoft/applicationinsights-core-js"
import {
  CommonLobbyPageModel,
  getIndustryListCount,
  getIndustrySearchSearchParam,
  getInitiationListCount,
  getInitiationSearchSearchParam,
  getMallFilterByAreaAreaName,
  getMallListCount,
  getMallSearchSearchParam,
  getOfficeFilterByAreaAreaName,
  getOfficeListCount,
  getOfficeSearchSearchParam,
  getSupermarketListCount,
  getSupermarketSearchSearchParam,
  IndustryPage,
  InitiationPage,
  MallPage,
  OfficePage,
  SupermarketPage,
} from "api"
import { MainRoutes } from "~/routes/mainRoutes"
import {
  getCommonFilterByAreaAreaName,
  getCommonFilterByCityCityName,
  getCommonListCount,
  getCommonSearchSearchParam,
  getIndustryFilterByAreaAreaName,
  getIndustryFilterByCityCityName,
  getInitiationFilterByAreaAreaName,
  getInitiationFilterByCityCityName,
  getMallFilterByCityCityName,
  getOfficeFilterByCityCityName,
  getSupermarketFilterByAreaAreaName,
  getSupermarketFilterByCityCityName,
} from "./../api/generated/AmotApi"

export type AssetType =
  | "all-assets"
  | "offices"
  | "shopping-mall"
  | "industry"
  | "in-progress"
  | "supermarket"
  | "construction"

export type AssetListType =
  | CommonLobbyPageModel
  | OfficePage
  | MallPage
  | IndustryPage
  | InitiationPage
  | SupermarketPage

export type AssetListWithoutSupermarketType =
  | OfficePage
  | MallPage
  | IndustryPage
  | InitiationPage

export const getAssetsByType = (type: AssetType, isLobby = false) => {
  switch (type) {
    case "all-assets":
      return getCommonListCount
    case "offices":
      return getOfficeListCount
    case "shopping-mall":
      return getMallListCount
    case "industry":
      return getIndustryListCount
    case "in-progress":
      return getInitiationListCount
    case "supermarket":
      return isLobby ? getSupermarketListCount : getOfficeListCount
    default:
      return getOfficeListCount
  }
}
export const getSearchAssetsByType = (type: AssetType) => {
  switch (type) {
    case "all-assets":
      return getCommonSearchSearchParam
    case "offices":
      return getOfficeSearchSearchParam
    case "shopping-mall":
      return getMallSearchSearchParam
    case "industry":
      return getIndustrySearchSearchParam
    case "in-progress":
      return getInitiationSearchSearchParam
    case "supermarket":
      return getSupermarketSearchSearchParam
    default:
      return getOfficeSearchSearchParam
  }
}
export const getAssetsFilterByAreaByType = (type: AssetType) => {
  switch (type) {
    case "all-assets":
      return getCommonFilterByAreaAreaName
    case "offices":
      return getOfficeFilterByAreaAreaName
    case "shopping-mall":
      return getMallFilterByAreaAreaName
    case "industry":
      return getIndustryFilterByAreaAreaName
    case "in-progress":
      return getInitiationFilterByAreaAreaName
    case "supermarket":
      return getSupermarketFilterByAreaAreaName
    default:
      return getOfficeFilterByAreaAreaName
  }
}

export const getAssetFilterByCityByType = (type: AssetType) => {
  switch (type) {
    case "all-assets":
      return getCommonFilterByCityCityName
    case "offices":
      return getOfficeFilterByCityCityName
    case "shopping-mall":
      return getMallFilterByCityCityName
    case "industry":
      return getIndustryFilterByCityCityName
    case "in-progress":
      return getInitiationFilterByCityCityName
    case "supermarket":
      return getSupermarketFilterByCityCityName

    default:
      return getOfficeFilterByCityCityName
  }
}

//function that return asset type based on the string which contains part of the asset type
export const getAssetTypeByString = (typeString: string) => {
  if (strContains(typeString, "all-assets")) {
    return "all-assets"
  } else if (strContains(typeString, "office")) {
    return "offices"
  } else if (strContains(typeString, "mall")) {
    return "shopping-mall"
  } else if (strContains(typeString, "industry")) {
    return "industry"
  } else if (strContains(typeString, "initiation")) {
    return "in-progress"
  } else if (strContains(typeString, "supermarket")) {
    return "supermarket"
  }
}

// function that return  assetLobby path based on the asset type
export const getAssetLobbyPath = (type: AssetType) => {
  switch (type) {
    case "all-assets":
      return MainRoutes.assetsList.allAssets
    case "offices":
      return MainRoutes.assetsList.offices
    case "shopping-mall":
      return MainRoutes.assetsList.shoppingMalls
    case "industry":
      return MainRoutes.assetsList.industry
    case "in-progress":
      return MainRoutes.assetsList.projectPlan
    case "supermarket":
      return MainRoutes.assetsList.supermarkets
    default:
      return MainRoutes.assetsList.offices
  }
}

export const getCategoryImageSource = (type, activeCategoryType) => {
  return `/${type}-${activeCategoryType === type ? `selected` : `white`}.webp`
}
