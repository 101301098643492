import { Hidden, useMediaQuery } from "@material-ui/core"
import type { FC } from "react"
import { Theme } from "theme/theme"

export type MediaQueryComponentProps = {
  aboveTheFold?: boolean
}

export const useIsDesktop = () =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"))

export const useIsLg = () =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"))

export const Mobile: FC<MediaQueryComponentProps> = ({
  children,
  aboveTheFold = false,
}) => (
  <Hidden implementation={aboveTheFold ? "css" : "js"} mdUp>
    {children}
  </Hidden>
)
export const Desktop: FC<MediaQueryComponentProps> = ({
  children,
  aboveTheFold = false,
}) => (
  <Hidden implementation={aboveTheFold ? "css" : "js"} smDown>
    {children}
  </Hidden>
)

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)"

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)"
