import { getMallListCount, MallPage } from "api"
import { UseSiteId } from "api/SiteId"
import React, { createContext, useContext, useEffect, useState } from "react"

type LayoutContext = {
  mallsItems: MallPage[] | []
}

const LayoutContext = createContext<LayoutContext>(null!)

export const LayoutProvider: React.FC = ({ children }) => {
  const [mallsItems, setMallsItems] = useState<MallPage[] | []>([])

  const siteId = UseSiteId()
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getMallListCount("-1", siteId)
      setMallsItems(data || [])
    }
    fetchData()
  }, [siteId])

  return (
    <LayoutContext.Provider value={{ mallsItems }}>
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => useContext(LayoutContext)
