import Link from "next/link"
import { useRouter } from "next/router"
import React, { memo } from "react"

type NextLinkProps = {
  href: string
  passHref?: boolean
  openInNewTab?: boolean
  children: React.ReactNode
  noScrollToTop?: boolean
}

const NextLink: React.FunctionComponent<NextLinkProps> = ({
  href,
  passHref,
  children,
  openInNewTab = false,
  noScrollToTop = false,
}) => {
  const { locale } = useRouter()
  // const idx = asPath.indexOf("?")
  // const search = idx >= 0 ? asPath.slice(idx) : undefined
  // const link: UrlObject = {
  //   pathname: href,
  //   // search
  //  }
  if (!href) {
    return null
  }

  return (
    <Link href={href} passHref scroll={!noScrollToTop} locale={locale}>
      {openInNewTab ? (
        <a target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : passHref ? (
        children
      ) : (
        <a>{children}</a>
      )}
    </Link>
  )
}

export default memo(NextLink)
