import { Typography } from "@material-ui/core"
import { Desktop, Mobile } from "common/MediaQueries"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import qs, { ParseQs } from "qs"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { fontCondensed } from "theme/GlobalStyle"

const StyledIframe = styled.iframe<{ $isAmot360: boolean }>`
  width: 100%;
  height: 100%;
  min-height: ${(p) => (p.$isAmot360 ? `600px` : `500px`)};

  ${(p) => p.theme.breakpoints.up("md")} {
    min-height: ${(p) => (p.$isAmot360 ? `500px` : `400px`)};
  }
`
const StyledContectUsTitle = styled(Typography).attrs({
  variant: "h2",
  component: "h2",
})`
  ${fontCondensed}
  font-size: 26px;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 27px;
`
const StyledAmotTitle = styled(Typography).attrs({
  variant: "h3",
  component: "h2",
})`
  font-size: 14px;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 32px;
`
const StyledSuccessMessage = styled(Typography)`
  font-size: 16px;
  line-height: 1.38;
  color: #13863b;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const makeParams = (queryString: string): ParseQs => {
  return qs.parse(queryString, {
    ignoreQueryPrefix: true,
  })
}

type ContactFormIframeProps = {
  withCompany: boolean
  generalMessage?: boolean
  isAmot360?: boolean
  selectedCategory?: string
  selectedFormName?: string
  setRef?: any
  isConstructions?: boolean
}

const ContactFormIframe = ({
  withCompany,
  generalMessage,
  isAmot360,
  selectedCategory,
  selectedFormName,
  setRef,
  isConstructions,
}: ContactFormIframeProps) => {
  const [iframeRedirect, setIframeRedirect] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { asPath, locale } = useRouter()
  const t = useTranslations("contactForm")
  const t360 = useTranslations("amot360")
  const [iframeUrl, setIframeUrl] = useState(
    `/${locale}/contact-us-form?pageName=${asPath}&withCompany=${withCompany}&isAmot360=${isAmot360}&selectedCategory=${selectedCategory}&selectedFormName=${selectedFormName}&isAmotConstruction=${isConstructions}`
  )

  useEffect(() => {
    setRef && setRef(iframeRef)
  }, [])

  const onLoad = () => {
    try {
      const iframeWindow = iframeRef.current?.contentWindow
      // console.log(iframeWindow)
      if (!iframeWindow) return
      const params = makeParams(iframeWindow.location.search)
      if (!params.pageName) {
        setIframeRedirect(true)
      }
    } catch (error) {
      setIframeRedirect(true)
      console.error({ error })
    }
  }

  useEffect(() => {
    setIframeUrl(
      `/${locale}/contact-us-form?pageName=${asPath}&withCompany=${withCompany}&isAmot360=${isAmot360}&selectedCategory=${selectedCategory}&selectedFormName=${selectedFormName}&isAmotConstruction=${isConstructions}`
    )
    // Resets the form when User navigates to other pages
    setIframeRedirect(false)
  }, [
    asPath,
    withCompany,
    locale,
    isAmot360,
    selectedCategory,
    selectedFormName,
  ])

  const getTitleText = () => {
    if (isAmot360) {
      return t360("contactFormTitle")
    }
    if (isConstructions) {
      return t("constructionsTitle")
    }
    if (generalMessage) {
      return t("generalDetails")
    }
    return t("details")
  }

  return (
    <>
      {iframeRedirect ? (
        <StyledSuccessMessage role="alert">
          {t("confirmation")}
        </StyledSuccessMessage>
      ) : (
        <>
          {!isAmot360 ? (
            <StyledContectUsTitle>{getTitleText()}</StyledContectUsTitle>
          ) : (
            <StyledAmotTitle>{getTitleText()}</StyledAmotTitle>
          )}

          <Desktop>
            {iframeUrl && (
              <StyledIframe
                //Every Iframe needs key for it to work properly and browser not to focus it on back button
                $isAmot360={isAmot360}
                key={`/${locale}/contact-us-form?pageName=${asPath}&withCompany=${withCompany}`}
                ref={iframeRef}
                src={`${iframeUrl}&isDesktop=true`}
                frameBorder="0"
                onLoad={onLoad}
                title="טופס יצירת קשר"
              />
            )}
          </Desktop>
          <Mobile>
            {iframeUrl && (
              <StyledIframe
                //Every Iframe needs key for it to work properly and browser not to focus it on back button
                $isAmot360={isAmot360}
                key={`/${locale}/contact-us-form?pageName=${asPath}&withCompany=${withCompany}`}
                ref={iframeRef}
                src={`${iframeUrl}&isDesktop=false`}
                frameBorder="0"
                onLoad={onLoad}
                title="טופס יצירת קשר"
              />
            )}
          </Mobile>
        </>
      )}
    </>
  )
}

export default ContactFormIframe
