import { useLocale } from "next-intl"

export enum SiteId {
  en = "7b7e3a65-5d4e-462c-8425-bd6112a3d38a",
  he = "",
}
export const getSiteIdByLocale = (locale: string): string => {
  switch (locale) {
    case "en":
      return SiteId.en
    case "he":
      return SiteId.he
    default:
      return SiteId.he
  }
}

export const UseSiteId = (): string => {
  const locale = useLocale()
  return getSiteIdByLocale(locale)
}
